import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from '@mui/material/styles/styled';
import { motion, useInView } from 'framer-motion';
import { useAngledBoop } from '../../utils/useBoop';
import { useBoop } from '../../utils/useBoop';
import StarPurple500Icon from '@mui/icons-material/StarPurple500';
import ButtonBase from '@mui/material/ButtonBase';

type Tprops = {
  numberOfItems?: number
}
type TtriggeringBoop = {
  trig: () => void
}
type TtransformTemplateProps = {
  y: number, rotateZ: number
}

const triggerFunction = (trig: Function): void => {
  trig()
}

const AnimateItemsFromCentreToAroundCircleAndTimeoutBack = (props: Tprops) => {
  const { numberOfItems = 24 } = props

  const [triggered, setTriggered] = useState(false)
  const refWrap = useRef(null)
  const isInView = useInView(refWrap)


  const triggeringBoop = useCallback((trig: Function): void => {
    trig()
  }, [])

  const { isBooped: isBoopedWrap, variants: varBoopWrap, trigger: triggerWrap } = useBoop({
    timeoutMs: 3000,
    animateTo: {
      y: -40,
      rotateZ: 360,
    },
    transitionTo: {
      type: "spring",
      stiffness: 100,
      damping: 3,
      duration: 1.0,
    },
    from: {
      y: 0,
      rotateZ: 0,
    },
  })


  const animBoop = useCallback(() => {
    if (window) {
      setTriggered(true)
      window.setTimeout(() => setTriggered(false), 600);
    }
  }, [])


  const transformTemplate = ({ y, rotateZ }: TtransformTemplateProps) => `rotateZ(${rotateZ}) translateY(${y})`

  return (
    <Wrapper ref={refWrap} >
      {isInView && (<Button
        onMouseEnter={() => {
          animBoop()
        }}
        onClick={() => {
          animBoop()
        }}
        className='clay'
        style={{ zIndex: 1 }}
      >
        <IconWrapper
          transformTemplate={transformTemplate}
          variants={varBoopWrap({ delayIndexDirectlyMs: 5000 })}
          animate={!isInView ? "" : isBoopedWrap ? "boop" : "rest"}
        >
          <StarPurple500Icon sx={{ fontSize: 48 }} />
        </IconWrapper>
      </Button>)}
      {isInView && Array(numberOfItems).fill(1).map((item, index) => {
        return (
          <BoopItem
            index={index}
            triggered={triggered}
            numberOfItems={numberOfItems}
            key={`myItem-${index}`}
          />)
      })}
    </Wrapper>
  );
}

const Wrapper = styled("div")`
  position: relative;
  margin: 0 auto;
    height: 400px;
    display: flex;
    align-items: center;
`;

const Button = styled(ButtonBase)`
  position: relative;
  z-index: 3;
  padding: 8px;
  border-radius: 50%;
`;
const IconWrapper = styled(motion.div)`
  display: block;
  svg {
    display: block;
    stroke: red;
    fill: blue
  }
`;

const Circle = styled(motion.div)`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  backgroundColor: hsl(50deg, 100%, 48%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding:0;
  `;


export default React.memo(AnimateItemsFromCentreToAroundCircleAndTimeoutBack);



type TBoopItemProp = {
  index: number,
  triggered: boolean, numberOfItems: number
}


function BoopItem({ index, triggered, numberOfItems }: TBoopItemProp) {

  const { isBooped, variants: varBoop, trigger } = useAngledBoop({ index, numberOfPoints: numberOfItems, wrapDiameterPx: 400, circleDiameterPx: 20, timeoutMs: 1000 + index * 500 });

  useEffect(() => {
    if (triggered && !isBooped) trigger()
  }, [triggered, isBooped, trigger])

  return <Circle
    variants={varBoop({ index: index })}
    animate={isBooped ? "boop" : "rest"}
    className='clay'
    key="c0"
  >
    {index + 1}
  </Circle>;
}
